var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "version-A", attrs: { id: "verify-order" } },
    [
      _vm.loaded
        ? [
            _vm.showTitle
              ? _c("h2", { staticClass: "title text-center mt-3 mb-5" }, [
                  _c("strong", [
                    _vm._v("\n        Verify Your Order for\n        "),
                    _c("span", { staticClass: "company-name" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.company?.name) +
                          "\n        "
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.processingOrder
              ? _c("ct-centered-spinner", { staticClass: "mt-4" }, [
                  _vm._v("\n      " + _vm._s(_vm.loaderMessage) + "\n    "),
                ])
              : _c(
                  "div",
                  { staticClass: "accordion", attrs: { role: "tablist" } },
                  [
                    _c("company-name-lookup", {
                      ref: "company-name-lookup-section",
                      on: {
                        "set-as-current": function ($event) {
                          return _vm.setSectionAsCurrent("company_name_lookup")
                        },
                        completed: function ($event) {
                          return _vm.updateSectionAsComplete(
                            "company_name_lookup"
                          )
                        },
                      },
                    }),
                    _c("business-address-section", {
                      ref: "business-address-section",
                      on: {
                        "set-as-current": function ($event) {
                          return _vm.setSectionAsCurrent("business_address")
                        },
                        completed: function ($event) {
                          return _vm.updateSectionAsComplete("business_address")
                        },
                      },
                    }),
                    _c("verify-data-section", {
                      ref: "verify-data-section",
                      on: {
                        "set-as-current": function ($event) {
                          return _vm.setSectionAsCurrent("verify_data")
                        },
                        completed: function ($event) {
                          return _vm.updateSectionAsComplete("verify_data")
                        },
                      },
                    }),
                    _c("processing-time-section", {
                      on: {
                        "set-as-current": function ($event) {
                          return _vm.setSectionAsCurrent("processing_time")
                        },
                        completed: function ($event) {
                          return _vm.updateSectionAsComplete("processing_time")
                        },
                      },
                    }),
                    _c("business-identity-section", {
                      on: {
                        "set-as-current": function ($event) {
                          return _vm.setSectionAsCurrent("business_identity")
                        },
                        completed: function ($event) {
                          return _vm.updateSectionAsComplete(
                            "business_identity"
                          )
                        },
                      },
                    }),
                    _c("important-new-business-items-section", {
                      on: {
                        "set-as-current": function ($event) {
                          return _vm.setSectionAsCurrent("additional_products")
                        },
                        completed: function ($event) {
                          return _vm.updateSectionAsComplete(
                            "additional_products"
                          )
                        },
                      },
                    }),
                    _c("submit-section", {
                      on: {
                        "set-as-current": function ($event) {
                          return _vm.setSectionAsCurrent("submit")
                        },
                        submit: _vm.verifyAccountInfoAndHandleSubmit,
                      },
                    }),
                  ],
                  1
                ),
          ]
        : _c("ct-centered-spinner", { staticClass: "mt-4" }, [
            _vm._v("\n    Loading...\n  "),
          ]),
      _c("collect-account-info", {
        ref: `collectAccountInfoModal-${_vm.uid}`,
        on: {
          success: function ($event) {
            return _vm.handleSubmit()
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }