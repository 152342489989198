<template>
  <b-container id="verify-order" class="version-A">
    <template v-if="loaded">
      <h2 v-if="showTitle" class="title text-center mt-3 mb-5">
        <strong>
          Verify Your Order for
          <span class="company-name">
            {{ company?.name }}
          </span>
        </strong>
      </h2>

      <ct-centered-spinner v-if="processingOrder" class="mt-4">
        {{ loaderMessage }}
      </ct-centered-spinner>

      <div v-else class="accordion" role="tablist">
        <!-- Section 1 (Company Name Lookup) ------------------------------------------------------>
        <company-name-lookup
          ref="company-name-lookup-section"
          @set-as-current="setSectionAsCurrent('company_name_lookup')"
          @completed="updateSectionAsComplete('company_name_lookup')"
        />

        <!-- Section 2 (Private Business Address) ------------------------------------------------->
        <business-address-section
          ref="business-address-section"
          @set-as-current="setSectionAsCurrent('business_address')"
          @completed="updateSectionAsComplete('business_address')"
        />

        <!-- Section 2 (Information to be Filed) -------------------------------------------------->
        <verify-data-section
          ref="verify-data-section"
          @set-as-current="setSectionAsCurrent('verify_data')"
          @completed="updateSectionAsComplete('verify_data')"
        />

        <!-- Section 3 (Processing Time) ---------------------------------------------------------->
        <processing-time-section
          @set-as-current="setSectionAsCurrent('processing_time')"
          @completed="updateSectionAsComplete('processing_time')"
        />

        <!-- Section 4 (Business Identity) -------------------------------------------------------->
        <business-identity-section
          @set-as-current="setSectionAsCurrent('business_identity')"
          @completed="updateSectionAsComplete('business_identity')"
        />

        <!-- Section 5 (Important New Business Items) --------------------------------------------->
        <important-new-business-items-section
          @set-as-current="setSectionAsCurrent('additional_products')"
          @completed="updateSectionAsComplete('additional_products')"
        />

        <!-- Section 6 (Submit) ------------------------------------------------------------------->
        <submit-section
          @set-as-current="setSectionAsCurrent('submit')"
          @submit="verifyAccountInfoAndHandleSubmit"
        />
      </div>
    </template>
    <ct-centered-spinner v-else class="mt-4">
      Loading...
    </ct-centered-spinner>
    <collect-account-info
      :ref="`collectAccountInfoModal-${uid}`"
      @success="handleSubmit()"
    />
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { tagSession } from '@/common/modules/inspectlet'

export default {
  name: 'VerifyOrder',
  components: {
    CompanyNameLookup:                () => import('@/components/VerifyOrder/sections/CompanyNameLookupSection'),
    BusinessAddressSection:           () => import('@/components/VerifyOrder/sections/BusinessAddressSection'),
    BusinessIdentitySection:          () => import('@/components/VerifyOrder/sections/BusinessIdentitySection'),
    CollectAccountInfo:               () => import('@/components/CollectAccountInfo'),
    CtCenteredSpinner:                () => import('@/components/shared/CtCenteredSpinner'),
    ImportantNewBusinessItemsSection: () => import('@/components/VerifyOrder/sections/ImportantNewBusinessItemsSection'),
    ProcessingTimeSection:            () => import('@/components/VerifyOrder/sections/ProcessingTimeSection'),
    SubmitSection:                    () => import('@/components/VerifyOrder/sections/SubmitSection'),
    VerifyDataSection:                () => import('@/components/VerifyOrder/sections/VerifyDataSection'),
  },
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loaded: false,
      processingOrder: false,
      loaderMessage: 'Loading...',
      uid: this._uid,
    }
  },
  computed: {
    ...mapGetters('account', [
      'account',
      'missingContactInformation',
    ]),
    ...mapGetters('verifyOrder', [
      'orderRequiringVerification',
      'company',
      'verifyOrderInteraction',
      'currentSection',
      'completedSections',
      'sections',
      'lastSection',
      'requiredSectionsComplete',
      'showSection',
      'sectionIsComplete',
      'sectionIsDisabled',
      'orderRequiringVerificationType',
    ]),
    ...mapGetters('checkout', [
      'allProducts',
      'cartEmpty',
      'findCartItem',
      'cartItems',
      'findProduct',
    ]),
    ...mapGetters('schema', [
      'currentObject',
      'wrapperValid',
    ]),
    ...mapGetters('jurisdictions', [
      'findByAbbreviation',
    ]),
  },
  async mounted() {
    this.resetWrapperValidationObservers()
    this.loaded = false
    tagSession({ verifyOrder: 'verifyOrder', companyId: this.company?.id })

    if (this.orderRequiringVerification) {
      await this.loadData()
      await this.loadDependentFilingMethod()
      if (!this.currentSection) this.setCurrentSection(this.sections[0])
    } else {
      // Redirect to Root/Home Page in case they some how navigates here
      // without an order requiring verification
      await this.$router.push({ name: 'root' }).catch(() => {})
    }

    if (this.currentObject) this.setCurrentProduct(this.currentObject?.product)
    this.loaded = true
  },
  methods: {
    ...mapActions('companies', [
      'loadActiveItems',
    ]),
    ...mapActions('vouchers', [
      'fetchCompanyVouchers',
    ]),
    ...mapActions('checkout', [
      'loadCartItems',
      'loadProducts',
      'setCurrentProduct',
    ]),
    ...mapActions('schema', [
      'loadObject',
      'resetWrapperValidationObservers',
      'setObject',
    ]),
    ...mapActions('verifyOrder', [
      'completeUpsell',
      'setCurrentSection',
      'setNextSection',
      'markSectionAsComplete',
      'loadVerifyOrderInteraction',
      'loadOptionalCategories',
      'loadOrderItemUpsells',
      'logVerifyOrderInteraction',
      'loadLatestOrderRequiringVerification',
      'loadDependentFilingMethod',
      'loadBusinessIdentity',
    ]),
    ...mapActions('freeTrials', [
      'determineTrialEligibility',
    ]),
    ...mapActions('virtualPhones', [
      'determinePhoneServiceTrialEligibility',
    ]),

    async loadData() {
      // This call needs to be done before the promise.all() call since products need to be loaded
      // for the loadCurrentObject method
      await this.ensureProductsLoaded()

      const promises = [
        // No await on these calls, since they're awaited in Promise.all() below and using "await"
        // here will interfere.
        this.loadVerifyOrderInteraction(),
        this.loadOrderItemUpsells(),
        this.loadOptionalCategories(),
        this.loadCurrentObject(),
        this.determinePhoneServiceTrialEligibility(this.company.id),
        this.determineTrialEligibility({
          company_id: this.company.id,
          product_category: 'law-on-call',
        }),
        this.loadBusinessIdentity(),
      ]
      await Promise.all(promises)
    },

    setSectionAsCurrent(section) {
      const verifyDataSection = this.currentSection === 'verify_data'

      if (!this.wrapperValid && verifyDataSection) {
        this.$refs['verify-data-section'].showMissingFields({ showErrors: true })
      } else {
        if (verifyDataSection) this.updateSectionAsComplete('verify_data')
        this.setCurrentSection(section)
      }
    },

    updateSectionAsComplete(section) {
      this.markSectionAsComplete(section)
    },

    async ensureProductsLoaded() {
      if (!this.productsLoaded) await this.loadProducts(this.company?.id)
    },
    async verifyAccountInfoAndHandleSubmit() {
      if (this.missingContactInformation) {
        const ref = `collectAccountInfoModal-${this.uid}`
        this.$refs[ref].show()
      } else {
        await this.handleSubmit()
      }
    },
    async handleSubmit() {
      await this.logVerifyOrderInteraction('complete-checkout')
      await this.completeCheckout()
      this.updateSectionAsComplete('submit')
    },
    async loadCurrentObject() {
      if (this.orderRequiringVerificationType === 'ShoppingBasketItem') {
        await this.createCartItemObject()
      } else {
        await this.loadObject({
          object_type: 'order-items',
          object_id: this.orderRequiringVerification.id,
        })
      }
    },

    async createCartItemObject() {
      const product = this.findProduct(this.orderRequiringVerification.product_id)
      const selectedFilingMethod = product?.filing_methods.find(fm =>
        fm.id = this.orderRequiringVerification.filing_method_id
      )

      let cartItemObject = {}
      cartItemObject['data'] = this.orderRequiringVerification.data
      cartItemObject['jurisdiction'] = this.findByAbbreviation(this.orderRequiringVerification?.display_info?.jurisdiction_abbreviation) || this.orderRequiringVerification?.filing_method?.jurisdiction
      cartItemObject['name'] = this.orderRequiringVerification.title
      cartItemObject['type'] = 'shopping-cart-filing'
      cartItemObject['id'] = this.orderRequiringVerification.id
      cartItemObject['company'] = this.company
      cartItemObject['product'] = {
        id: this.orderRequiringVerification.product_id,
        filing_name: product.filing_name,
        filing_method: selectedFilingMethod || {},
        kind: product.kind,
      }
      await this.setObject({ object: cartItemObject })
    },

    async completeCheckout() {
      const verifyOrderForm = this.$refs['verify-data-section'].$refs['verify-order-form']

      // TODO: verifyOrderForm having a falsy value is a rare but recurring issue.
      // We have not been able to re-produce the deeper problem yet to pursue a solution.
      // For now I am handling the unexpected behavior with safe operators, preventing
      // checkout, and showing a toast to give a more context.

      const preventCheckout = !this.wrapperValid ||
        !verifyOrderForm ||
        verifyOrderForm?.editMode ||
        verifyOrderForm?.loading

      if(!verifyOrderForm) {
        this.$bvToast.toast('Invalid Order Form.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }

      if (preventCheckout) {
        await verifyOrderForm?.editButtonClicked({ showErrors: true })
        return
      }

      this.loaderMessage = 'Processing Order...'
      this.processingOrder = true

      const result = await this.completeUpsell()

      if (result.success) {
        const invoiceId = result.response?.sales_invoice_id
        const promises = [
          this.loadCartItems(),
          this.loadLatestOrderRequiringVerification({}),
          this.loadActiveItems({ id: this.company.id }),
          this.fetchCompanyVouchers(this.company.id),
        ]

        await Promise.all(promises)

        this.$emit('completed', invoiceId)
      } else {
        this.processingOrder = false
        this.$bvToast.toast('Unable to Complete Order. Please contact customer service.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },
  },
}
</script>
<style lang="scss">

@mixin button-small {
  border-radius: 0.25em;
  padding: 0.313em 1.25em;
}

#verify-order {
  .standard-verify-button-size {
    min-width: 9.063em !important;
    min-height: 2.5em !important;
  }

  button {
    @include button-small;
    font-weight: 700;

    &.btn-default {
      color: #31BFBB !important;
    }
  }

  .title {
    font-weight: 300;
    margin-top: 0.5em;
    .company-name {
      font-weight: 700;
      color: #000864;
    }
  }

  &.version-A {
    #verify-order-form {
      margin-bottom: 1.25em;
    }
    .accordion {
      .card {
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.625em;
        margin-bottom: 1.313em;
      }
      .accordion-header {
        * { cursor: pointer !important }

        &.disabled {
          * { cursor: not-allowed !important }
          pointer-events: none;
          opacity: .45;
        }
        &.collapsed {
          background: white;
          .feather-chevron-right { transform: rotate(-90deg) }
        }
        &.not-collapsed {
          border-radius: 0.625em 0.625em 0 0 !important;
          .feather-chevron-right { transform: rotate(90deg) }
        }
        padding: 1.25em 1.5em !important;
        border-radius: 0.625em !important;

        .header-left {
          flex-wrap: wrap;

          &, * {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          h4 {
            margin: 0;
            font-weight: 800;
          }
          h5 {
            font-weight: 800;
            font-size: 1.1em;

            &, * {
              margin: 0 0.625em 0 0.438em;
              color: #31bfbb !important;
              fill: #31bfbb !important;
            }
          }
        }
      }
      .accordion-footer {
        background: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        button {
          margin: 0 0.625em;
        }
      }
    }
  }
}

@media only screen and (max-width: 770px) {
  #verify-order {
    .accordion-header {
      .card-body {
        padding: 0;
      }
      .header-left {
        flex-flow: column nowrap !important;
        align-items: flex-start !important;
        margin-left: 0;

        h4 {
          font-size: 1.2em !important;
          padding-bottom: 0.2em;
        }

        h5 {
          font-size: 0.8em !important;
          margin-left: 0 !important;

          div {
            margin-left: 0!important;
          }
          .check-circle {
            order: 2;
            margin-left: 0;
          }
        }
      }
    }
  }
}
</style>
